import type { TransitionProps } from '@mui/material/transitions';
import { Button, Chip, DialogContent, Slide, Stack } from '@mui/material';
import { ArrowNarrowDownIcon } from 'src/mui/_icons';
import { LoadingButton } from '@mui/lab';
import { FormProvider } from 'react-hook-form';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { useCreateBill, useCreateRecurringBill } from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { isStructuredFormError, setErrorsInForm } from 'src/libs/finbits/Form';
import { buildRecurrencePayload } from 'src/libs/finbits/Management/ScheduledRecurrences';

import TabPanelsProvider from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider';
import { useOpenConfirmLeaveFormDialog } from 'src/features/entries/Dialogs';
import BillContent from 'src/features/bills-to-pay/BillContent';
import useBillForm from 'src/features/bills-to-pay/useBillForm';
import EntrySuggestionsProvider from 'src/features/entries/Suggestions/EntrySuggestionsProvider';

import EditableFieldsProvider from '../BillContent/EditableFieldsProvider';

import styles from './CreateBillModal.module.scss';

type Props = {
  inboxItemId?: string;
  onSuccess: () => void;
} & PortalProps;

export default function CreateBillModal({
  inboxItemId,
  onClose,
  onSuccess,
  open = true,
  onExit,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const openLeaveDialog = useOpenConfirmLeaveFormDialog();
  const { createBill, isLoading } = useCreateBill();
  const { createRecurringBill, isLoading: isRecurrenceLoading } =
    useCreateRecurringBill();

  const { ...form } = useBillForm({ inboxItemId });
  const {
    formState: { isDirty },
    watch,
  } = form;

  function handleClose() {
    if (isDirty) {
      return openLeaveDialog({
        onConfirm: onClose,
      });
    }

    onClose();
  }

  function handleCreateBill({
    attachments,
    classifications,
    ...params
  }: BillFormParams) {
    const routeParams = {
      companyId,
      organizationId,
    };

    const handleApiResponse = {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          message: params.isRecurrenceEnabled
            ? 'Recorrência criada com sucesso'
            : 'O novo lançamento a pagar foi adicionado com sucesso!',
        });

        onSuccess();

        onClose();
      },
      onError: ({ response }: ApiError<ApiErrorForm>) => {
        snackbar({
          variant: 'error',
          message: params.isRecurrenceEnabled
            ? 'Ocorreu um erro ao criar a recorrência'
            : 'Ocorreu um erro ao criar o lançamento a pagar!',
        });

        if (response?.data.errors && isStructuredFormError(response?.data)) {
          setErrorsInForm(response.data.errors, form.setError);
        }
      },
    };

    // TODO: Remove once ticket https://finbits.height.app/T-6584 is done
    if (!params.paymentDetails?.boletoId) {
      delete params.paymentDetails?.boletoId;
    }

    if (params.isRecurrenceEnabled) {
      const recurrencePayload = buildRecurrencePayload(
        {
          attachments,
          classifications,
          ...params,
        },
        !!inboxItemId ? [inboxItemId] : []
      );

      createRecurringBill(
        {
          ...recurrencePayload,
          ...routeParams,
        },
        handleApiResponse
      );

      return;
    }

    createBill(
      {
        ...params,
        inboxItemId,
        attachmentsIds: attachments?.map((attachment) => attachment.id),
        classifications: Object.values(classifications),
        ...routeParams,
      },
      handleApiResponse
    );
  }

  async function handleClick() {
    form.handleSubmit(handleCreateBill)();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={handleClose}
      fullWidth
      maxWidth="uxl"
      // TODO: Habilitar essa prop novamente quando tivermos a drawer de contato usando componentes do MUI
      disableEnforceFocus
    >
      <Dialog.Header
        size="small"
        title={
          <Stack direction="row" gap={2} alignItems="center">
            Pagamento Programado
            <Chip
              label="Saída"
              color="error"
              size="small"
              icon={
                <ArrowNarrowDownIcon
                  strokeWidth="2.5"
                  className={styles.icon}
                />
              }
            />
          </Stack>
        }
        onClose={handleClose}
      />

      <DialogContent className={styles.dialogContent} dividers>
        <FormProvider {...form}>
          <EditableFieldsProvider>
            <TabPanelsProvider>
              <EntrySuggestionsProvider
                attachments={watch('attachments')}
                inboxItemId={inboxItemId}
                contactId={watch('contactId')}
              >
                <BillContent />
              </EntrySuggestionsProvider>
            </TabPanelsProvider>
          </EditableFieldsProvider>
        </FormProvider>
      </DialogContent>

      <Dialog.Actions className={styles.dialogActions}>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          loading={isLoading || isRecurrenceLoading}
          onClick={handleClick}
          variant="contained"
          color="primary"
        >
          {form.watch('isRecurrenceEnabled')
            ? 'Salvar e criar recorrência'
            : 'Salvar'}
        </LoadingButton>
      </Dialog.Actions>
    </Dialog>
  );
}
