import { useEffect, useMemo, useState } from 'react';

import { Box, Drawer, Stack, Typography } from '@mui/material';
import _debounce from 'lodash/debounce';

import { IconButton, SearchInput } from 'src/mui';

import FinBot from 'src/pages/Organizations/Companies/FinBot';

import type { Classification } from 'src/libs/finbits/Classifications/types';
import { format } from 'src/libs/finbits/Date';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { useFinancialStatementEntries } from 'src/libs/finbits/Management/FinancialStatements/Entries/Entries';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { ConnectionProviderType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import EntriesDataGrid from 'src/features/entries/EntriesDataGrid';
import EntriesSummary from 'src/features/entries/EntriesSummary/EntriesSummary';
import useEntriesParams from 'src/features/entries/useEntriesParams';
import BotAvatar from 'src/features/finbot/BotAvatar';

import styles from './EntriesStatement.module.scss';

type Props = { classifications: Classification[] };

export default function EntriesStatement({ classifications }: Props) {
  const { isEnabled: isFinbotEnabled } = useExternalFeatureFlag(Feature.FINBOT);

  const [searching, setSearching] = useState(false);
  const [quickFilter, setQuickFilter] = useState<string[]>([]);
  const [hasPluggy, setHasPluggy] = useState(false);

  const { organizationId, companyId, selectedAccountIds, selectedDateRange } =
    useEntriesParams();

  const {
    data,
    isLoading: isLoadingEntries,
    isFetching: isFetchingEntries,
  } = useFinancialStatementEntries({
    organizationId,
    companyId,
    accountsIds: selectedAccountIds,
    startDate: format(selectedDateRange[0], 'yyyy-MM-dd'),
    endDate: format(selectedDateRange[1], 'yyyy-MM-dd'),
    groupSplits: false,
  });

  const isDataGridLoading = useMemo(
    () => isFetchingEntries || isLoadingEntries,
    [isLoadingEntries, isFetchingEntries]
  );

  useEffect(() => {
    if (!!data && !!data.accounts) {
      setHasPluggy(
        data.accounts.some(
          ({ connectionProvider }) =>
            connectionProvider === ConnectionProviderType.PLUGGY
        )
      );
    }
  }, [data]);

  const entries: FinancialStatementEntry[] = useMemo(() => {
    return data?.entries ?? [];
  }, [data]);

  const quickFilterWithDebounce = useMemo(
    () =>
      _debounce((value) => {
        setQuickFilter(value.trim().split(' '));
        setSearching(false);
      }, 350),

    [setQuickFilter]
  );

  function handleChangeSearch(event: any) {
    setSearching(true);
    quickFilterWithDebounce(event);
  }

  const [openFinBot, setOpenFinBot] = useState(false);

  function toggleFinBotDrawer(newOpen: boolean) {
    setOpenFinBot(newOpen);
  }

  return (
    <Box
      py={4}
      px={12}
      display="flex"
      flexDirection="column"
      className={styles.container}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
        gap={6}
      >
        <Stack justifyContent="space-between" gap={4}>
          <Typography variant="subtitle1" fontWeight="bold">
            Lançamentos
          </Typography>

          <SearchInput
            onChange={handleChangeSearch}
            loading={searching}
            className={styles.searchInput}
          />
        </Stack>

        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4, lg: 4 }}>
          {isFinbotEnabled && (
            <IconButton
              className={styles.botButton}
              color="info"
              onClick={() => toggleFinBotDrawer(true)}
              title="Insights sobre a empresa"
            >
              <Stack alignItems="center">
                <BotAvatar />
                <Typography fontWeight={800} color="info.600">
                  FinBot
                </Typography>
              </Stack>
            </IconButton>
          )}

          {!!data && (
            <EntriesSummary
              data={data}
              selectedEndDate={selectedDateRange[1]}
              selectedStartDate={selectedDateRange[0]}
            />
          )}
        </Stack>
      </Stack>

      <EntriesDataGrid
        entries={entries}
        classifications={classifications}
        loading={isDataGridLoading}
        quickFilter={quickFilter}
        alertInfo={
          hasPluggy
            ? 'A importação de lançamentos que não são do Banco BTG ocorre em até 24 horas.'
            : undefined
        }
      />

      <Drawer
        classes={{ paper: styles.drawer }}
        anchor="right"
        open={openFinBot}
        onClose={() => toggleFinBotDrawer(false)}
      >
        <FinBot isPage={false} />
      </Drawer>
    </Box>
  );
}
