import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  DialogContent,
  Divider,
  Slide,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import type { TransitionProps } from '@mui/material/transitions';
import { FormField, Input } from 'src/mui/_scss';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { AlertCircleIcon } from 'src/mui/_icons';

import { Dialog, Typography } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { ApprovalRulesFormResolver } from 'src/libs/finbits/ApprovalRules/validations';
import { ApprovalRuleConditionField } from 'src/libs/finbits/ApprovalRules/types';
import type {
  ApprovalRuleFormParams,
  ApprovalRulePostParams,
} from 'src/libs/finbits/ApprovalRules/types';
import { setErrorsInForm } from 'src/libs/finbits/Form';
import type { ApiErrorForm } from 'src/libs/finbits/client';

import ApprovalTypeSelect from 'src/features/approval/ApprovalTypeSelect';
import AssigneeSelect from 'src/features/assignee/AssigneeSelect';

import ConditionFields from './ConditionFields';
import styles from './ApprovalRuleModal.module.scss';

type Props = {
  handleSubmit: (
    params: ApprovalRulePostParams,
    setErrorsInForm?: (error: ApiErrorForm) => void
  ) => void;
  title: string;
  isLoading?: boolean;
  defaultValues: ApprovalRuleFormParams;
  isDefaultRule?: boolean;
} & PortalProps;

function fieldToValidValue(field: string | null) {
  const isValidField = Object.values(ApprovalRuleConditionField).some(
    (validField) => validField === field
  );

  return isValidField ? field : ApprovalRuleConditionField.LABEL;
}

function parseFormParams({
  isDefaultRule,
  assignees,
  conditions,
  ...params
}: ApprovalRuleFormParams & {
  isDefaultRule: boolean;
}): ApprovalRulePostParams {
  const parsedConditions = isDefaultRule
    ? []
    : conditions.map((condition) => {
        return {
          ...condition,
          type: condition.type!,
          field: fieldToValidValue(condition.field)!,
          categoriesValues: condition.categoriesValues?.categories.map(
            (category) => ({ categoryId: category.id })
          ),
          labelsValues: condition.labelsValues?.labels.map((label) => ({
            labelId: label.id,
          })),
        };
      });

  return {
    ...params,
    conditions: parsedConditions,
    assigneesIds: assignees.map((a) => a.id),
  };
}

export default function ApprovalRuleModal({
  open = true,
  onClose,
  onExit,
  handleSubmit,
  title,
  isLoading = false,
  defaultValues,
  isDefaultRule = false,
}: Props) {
  const form = useForm<ApprovalRuleFormParams>({
    resolver: zodResolver(ApprovalRulesFormResolver),
    defaultValues,
  });

  const selectedAssignees = form.watch('assignees');

  function setErrors(errors: ApiErrorForm) {
    setErrorsInForm(errors, form.setError);
  }

  function handleParseSubmitParams(params: ApprovalRuleFormParams) {
    const parsedParams = parseFormParams({ isDefaultRule, ...params });
    return handleSubmit(parsedParams, setErrors);
  }

  return (
    <Dialog
      title={title}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
    >
      <Dialog.Header title={title} onClose={onClose} />
      <FormProvider {...form}>
        <DialogContent className={styles.dialogContent} dividers>
          {isDefaultRule && (
            <Alert
              variant="outlined"
              severity="warning"
              aria-labelledby="default-rule-alert-title"
              icon={<AlertCircleIcon fontSize="inherit" />}
            >
              <AlertTitle>
                <Typography fontWeight={700} id="default-rule-alert-title">
                  A regra de aprovador(es) padrão é a de menor prioridade.
                </Typography>
              </AlertTitle>
              <Typography fontSize={14}>
                Ela será aplicada caso nenhuma outra regra que você criar atenda
                ao lançamento a pagar.
              </Typography>
            </Alert>
          )}

          <FormField label="Nome ou descrição" name="description">
            {(field) => {
              if (isDefaultRule) {
                return (
                  <Typography variant="body1" fontSize={16} color="grey.500">
                    {field.value}
                  </Typography>
                );
              }

              return (
                <Input {...field} placeholder="Digite um nome para a regra" />
              );
            }}
          </FormField>

          <Divider />

          <ConditionFields isDefaultRule={isDefaultRule} />

          <Divider />

          <FormField label="Aprovadores" name="assignees">
            {(field) => {
              return (
                <AssigneeSelect
                  {...field}
                  disableCloseOnSelect
                  multiple
                  placeholder="Digite o nome do(s) aprovadore(s)"
                  onChange={(_e, value) => field.onChange(value)}
                />
              );
            }}
          </FormField>

          <Collapse
            in={Boolean(selectedAssignees.length > 1)}
            classes={{
              wrapper: styles.collapse,
            }}
          >
            <FormField
              label="Aprovações necessárias"
              name="approvalType"
              tooltip="Informe se todos precisam aprovar o lançamento ou se a aprovação de um dos aprovadores selecionados é suficiente."
            >
              {(field) => {
                return (
                  <ApprovalTypeSelect
                    {...field}
                    onChange={(_e, value) => field.onChange(value)}
                  />
                );
              }}
            </FormField>
          </Collapse>
        </DialogContent>
        <Dialog.Actions>
          <Button size="medium" variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoading}
            size="medium"
            color="primary"
            variant="contained"
            type="submit"
            onClick={form.handleSubmit(handleParseSubmitParams)}
          >
            Salvar
          </LoadingButton>
        </Dialog.Actions>
      </FormProvider>
    </Dialog>
  );
}
