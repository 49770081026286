import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import type {
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { singleSelectColumn } from 'src/mui/_scss';
import { Stack, Typography } from '@mui/material';
import FilterWrapper from 'src/mui/_scss/Table/Columns/singleSelectColumn/FilterWrapper';

import type { User } from 'src/libs/finbits/Organization/Users/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';
import AssigneeChip from 'src/features/assignee/AssigneeChip';
import AssigneeSelect from 'src/features/assignee/AssigneeSelect';

function renderCell({
  value,
  rowNode,
  row,
}: Partial<GridRenderCellParams<Partial<BillPayable>, any>>) {
  if (rowNode?.type === 'group') {
    return <Typography>{value}</Typography>;
  }

  return (
    <Stack direction="row" gap={2} overflow="hidden" textOverflow="ellipsis">
      {value?.map((assignee: User, index: number) => (
        <AssigneeChip
          key={index}
          showIcon
          assignee={assignee}
          assignments={row?.lastApproval?.assignments}
          billStatus={row?.status}
        />
      ))}
    </Stack>
  );
}

const filterOperators: GridFilterOperator[] = [
  {
    label: 'é',
    value: 'is',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (value: User[]): boolean => {
        return (
          value?.some((assignee) => assignee.id === filterItem.value?.id) ??
          false
        );
      };
    },
    InputComponentProps: {
      Component: AssigneeSelect,
    },
    InputComponent: FilterWrapper,
  },
  {
    label: 'não é',
    value: 'not',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (value: User[]): boolean => {
        return (
          value?.every((assignee) => assignee.id !== filterItem.value?.id) ??
          false
        );
      };
    },
    InputComponentProps: {
      Component: AssigneeSelect,
    },
    InputComponent: FilterWrapper,
  },
];

function parserAssingneeToSort(assignees: User[] | string) {
  if (typeof assignees === 'string') return assignees;

  const assigneeNames =
    assignees
      ?.map(({ name }: User) => name)
      .sort()
      .join() ?? '';

  return assigneeNames;
}

function getAssigneesIds(assignee: User | string) {
  return isObject(assignee) ? assignee?.id : assignee;
}

type UpdatedValues = User[] | string[];

function hasChangeValidator(
  newValue: UpdatedValues,
  oldValues?: UpdatedValues
) {
  if (oldValues === undefined) return false;

  const newLabelIds = newValue?.map(getAssigneesIds) ?? [];
  const oldLabelIds = oldValues?.map(getAssigneesIds) ?? [];

  return !isEqual(newLabelIds, oldLabelIds);
}

export function assigneesColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return singleSelectColumn({
    columnProps: {
      minWidth: 100,
      flex: 2,
      editable: false,
      aggregable: false,
      headerName: 'Aprovadores',
      renderCell: renderCell,
      getOptionValue: (value: any) => value?.id,
      getOptionLabel: (value: any) => value?.name,
      sortComparator: (assigneesA, assigneesB) => {
        const assigneesANames = parserAssingneeToSort(assigneesA);
        const assigneesBNames = parserAssingneeToSort(assigneesB);

        return assigneesANames.localeCompare(assigneesBNames);
      },
      valueGetter: (value, row: T) => row.assignees,
      valueFormatter: (value: User[]) => {
        return value?.map((assignee) => assignee.name);
      },
      groupingValueGetter: (value, row) => {
        if (row?.assignees?.length === 0) return 'Sem aprovadores';

        return row?.assignees
          ?.map((assignee: User) => assignee.name)
          .join(', ');
      },
      valueSetter: (value: User[], row) => {
        const assigneesIds = value?.map((assignee) => assignee.id) ?? null;

        return {
          ...row,
          assignees: value,
          assigneesIds,
          updated: { assignees: value, assigneesIds },
          hasChangeValidator,
        };
      },
      ...columnProps,
      field: 'assigneesIds',
      filterOperators,
    },
    SelectComponent: AssigneeSelect,
    selectProps: {
      multiple: true,
      autoFocus: true,
    },
  });
}
