import { isEnabledLDFeature, useFlags } from 'src/libs/launchDarkly';

export enum Feature {
  APPROVAL_RULES_PAGE = 'approvalRulesPage',
  BATCH_UPLOAD = 'batchUpload',
  EXAMPLE = 'example',
  FINBOT = 'finbot',
  INITIAL_BALANCE_MODULE = 'initialBalanceModuleApproval',
  LINK_BILL_FROM_INBOX_ITEM_NEW_MODAL = 'linkBillFromInboxItemNewModal',
  NEW_NOTA_FISCAL_SETUP = 'newNotaFiscalSetup',
  NEW_PURCHASE_ORDER_FORM_FIELDS = 'newPurchaseOrderFormFields',
  SUGGESTIONS_TAB = 'suggestionsTab',
}

type OpenFlags = Record<Feature, boolean>;

export const openFlags: Partial<OpenFlags> = {
  [Feature.EXAMPLE]: true,
  [Feature.NEW_NOTA_FISCAL_SETUP]: true,
  [Feature.NEW_PURCHASE_ORDER_FORM_FIELDS]: true,
  [Feature.SUGGESTIONS_TAB]: true,
  [Feature.APPROVAL_RULES_PAGE]: true,
};

export function useExternalFeatureFlag(feature: Feature) {
  const flags = useFlags();

  const isEnabled = isEnabledLDFeature(feature, flags);

  return { isEnabled };
}
