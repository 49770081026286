import type { ChipProps, Color, Components } from '@mui/material';
import { alpha } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiChip({
  palette,
  typography,
}: ThemeConfig): Components['MuiChip'] {
  const isLightMode = palette.mode === 'light';

  const { 50: infoBg, 700: infoColor } = palette.info as Color;

  const {
    600: errorColorLight,
    400: errorColorDark,
    600: errorBGColor,
  } = palette.error as Color;

  const {
    600: warningColorLight,
    400: warningColorDark,
    500: warningBGColor,
  } = palette.warning as Color;

  const {
    500: successColorLight,
    500: successColorDark,
    600: successBGColor,
  } = palette.success as Color;

  const {
    600: defaultColorLight,
    400: defaultColorDark,
    700: defaultBGColor,
  } = palette.grey as Color;

  const chipErrorBackground = isLightMode
    ? alpha(errorBGColor, 0.08)
    : alpha(errorBGColor, 0.1);

  const chipWarningBackground = isLightMode
    ? alpha(warningBGColor, 0.08)
    : alpha(warningBGColor, 0.1);

  const chipSuccessBackground = isLightMode
    ? alpha(successBGColor, 0.08)
    : alpha(successBGColor, 0.1);

  const chipDefaultBackground = isLightMode
    ? alpha(defaultBGColor, 0.08)
    : alpha(palette.common?.white!, 0.06);

  return {
    styleOverrides: {
      root: ({ ownerState }: { ownerState: ChipProps }) => ({
        ...(ownerState.color === 'default' && {
          color: isLightMode ? defaultColorLight : defaultColorDark,
          backgroundColor: chipDefaultBackground,
        }),
        ...(ownerState.color === 'secondary' && {
          color: isLightMode ? palette.common?.black : palette.common?.white,
          ...(ownerState.variant === 'filled' && {
            color: palette.common?.white,
          }),
        }),
        ...(ownerState.color === 'error' && {
          color: isLightMode ? errorColorLight : errorColorDark,
          backgroundColor: chipErrorBackground,
        }),
        ...(ownerState.color === 'success' && {
          color: isLightMode ? successColorLight : successColorDark,
          backgroundColor: chipSuccessBackground,
        }),
        ...(ownerState.color === 'warning' && {
          color: isLightMode ? warningColorLight : warningColorDark,
          backgroundColor: chipWarningBackground,
          // TODO: Fix hover when we have onClick - review in D.S
          border: `1px solid ${chipWarningBackground}`,
          '&&:hover': {
            backgroundColor: chipWarningBackground,
            border: `1px solid ${
              isLightMode ? warningColorLight : warningColorDark
            }`,
          },
        }),
        ...(ownerState.color === 'info' && {
          color: infoColor,
          backgroundColor: infoBg,
        }),
        ...(ownerState.size === 'xsmall' && {
          height: 18,
          padding: '4px',
        }),
      }),
      icon: ({ ownerState }: { ownerState: ChipProps }) => ({
        ...(ownerState.size === 'xsmall' && {
          marginRight: '-2px',
        }),
      }),
      label: ({ ownerState }: { ownerState: ChipProps }) => ({
        minWidth: '35px',
        ...typography.caption,
        ...(ownerState.size === 'xsmall' && {
          fontSize: 11,
          fontWeight: 600,
          padding: 0,
          minWidth: 10,
        }),
      }),
    },
  };
}
