import { Suspense, lazy } from 'react';

import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Unstable_Grid2';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import {
  useIsEnabledBillsApprovalsInCompany,
  useIsEnabledBillsApprovalsRulesInCompany,
} from 'src/libs/finbits/Organization/Companies/Options';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import {
  AccountsField,
  AmountField,
  ApprovalTypeField,
  AssigneesField,
  CategoriesField,
  CommentsField,
  ContactsField,
  DateField,
  DescriptionField,
  PaymentMethodField,
  UploadField,
} from 'src/features/entries/Fields';
import { useEntrySuggestionsContext } from 'src/features/entries/Suggestions/EntrySuggestionsProvider';
import { EditableFields } from 'src/features/EntryForm/types';
import { useEditableFieldsContext } from 'src/features/bills-to-pay/BillContent/EditableFieldsProvider';

const PaymentFields = lazy(
  () => import('src/features/entries/Fields/PaymentFields')
);

export default function InformationFields() {
  const { control } = useFormContext();
  const {
    suggestionSelected,
    suggestionFromContact,
    isLoadingSuggestions,
    updateSuggestionSelected,
  } = useEntrySuggestionsContext();
  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();
  const { isEnabledBillsApprovalsRules } =
    useIsEnabledBillsApprovalsRulesInCompany();
  const { isDisabledField } = useEditableFieldsContext();

  const showApprovalFields =
    isEnabledBillsApprovals && !isEnabledBillsApprovalsRules;

  function handleContactChange(value: Contact | null) {
    if (value === null) {
      updateSuggestionSelected({ contact: null });
    }
  }

  return (
    <Grid component="form" container spacing={6}>
      <Grid xs={12}>
        <DescriptionField
          required
          name="description"
          control={control}
          disabled={isDisabledField(EditableFields.description)}
        />
      </Grid>

      <Grid xs={6}>
        <AmountField
          required
          name="amount"
          control={control}
          disabled={isDisabledField(EditableFields.amount)}
        />
      </Grid>

      <Grid xs={6}>
        <AccountsField
          required
          name="accountId"
          control={control}
          disabled={isDisabledField(EditableFields.accountId)}
        />
      </Grid>

      <Grid xs={6}>
        <DateField
          name="date"
          control={control}
          label="Data de pagamento"
          required
          disabled={isDisabledField(EditableFields.date)}
        />
      </Grid>

      <Grid xs={6}>
        <DateField
          name="relevantDate"
          control={control}
          label="Competência"
          placeholder="Selecione um mês"
          DatePickerProps={{
            views: ['month', 'year'],
            format: 'MMM/yyyy',
          }}
          disabled={isDisabledField(EditableFields.relevantDate)}
        />
      </Grid>

      <Grid xs={6}>
        <ContactsField
          suggestion={suggestionSelected?.contact}
          name="contactId"
          control={control}
          onChange={handleContactChange}
          disabled={isDisabledField(EditableFields.contactId)}
        />
      </Grid>

      <Grid xs={6}>
        <CategoriesField
          name="categoryId"
          type={BalanceType.DEBIT}
          control={control}
          suggestion={suggestionFromContact?.current?.category}
          isLoadingSuggestion={isLoadingSuggestions}
          disabled={isDisabledField(EditableFields.categoryId)}
        />
      </Grid>

      {showApprovalFields && (
        <>
          <Grid xs={12}>
            <AssigneesField
              name="assigneesIds"
              control={control}
              disabled={isDisabledField(EditableFields.assigneesIds)}
            />
          </Grid>

          <Grid xs={6}>
            <ApprovalTypeField
              name="approvalType"
              control={control}
              disabled={isDisabledField(EditableFields.approvalType)}
            />
          </Grid>
        </>
      )}

      <Grid xs={showApprovalFields ? 6 : 12}>
        <PaymentMethodField
          suggestion={{
            value:
              suggestionFromContact?.current?.paymentDetails?.paymentMethod,
            isLoading: isLoadingSuggestions,
          }}
          control={control}
          name="paymentDetails.paymentMethod"
          disabled={isDisabledField(EditableFields.paymentDetails)}
        />
      </Grid>

      <Suspense>
        <PaymentFields />
      </Suspense>

      <Grid xs={12}>
        <UploadField
          name="attachments"
          control={control}
          disabled={isDisabledField(EditableFields.attachmentsIds)}
        />
      </Grid>

      <Grid xs={12}>
        <CommentsField
          name="comments"
          control={control}
          disabled={isDisabledField(EditableFields.comments)}
        />
      </Grid>
    </Grid>
  );
}
