import { useMemo, useState } from 'react';

import { AlertTitle, Box, Button, Chip } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-premium';
import isAfter from 'date-fns/isAfter';
import { Alert } from 'src/design-system/components';
import { AlertTriangleIcon, RefreshCW05Icon } from 'src/mui/_icons';
import { Table } from 'src/mui/_scss';

import { DatePicker, Tooltip, Typography } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { RoutingNumber } from 'src/libs/finbits/Bank/types';
import { format } from 'src/libs/finbits/Date';
import { toCurrency } from 'src/libs/finbits/Money';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useSummaryAccountsBalances } from 'src/libs/finbits/Organization/Companies/Balances';
import type { Account } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import { BalanceInconsistency } from 'src/features/entries/BalanceInconsistency';
import type { BalanceInconsistency as BalanceInconsistencyType } from 'src/features/entries/BalanceInconsistency/types';
import { accountColumn } from 'src/features/entries/EntriesDataGrid/columns/accountColumn/accountColumn';
import { amountColumn } from 'src/features/entries/EntriesDataGrid/columns/amountColumn/amountColumn';

import BalanceCTABTG from '../BalanceCTABTG';
import InitialBalanceEmptyState from '../InitialBalanceEmptyState';

import styles from './AccountsBalances.module.scss';
import { dateColumn } from './Columns/DateColumn/DateColumn';
import TotalSum from './GridToolbar/TotalSum';

type Props = {
  onClose?: () => void;
  onRegister?: (account: Account) => void;
};

export default function AccountsBalances({ onClose, onRegister }: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const [filterDate, setFilterDate] = useState<Date>(new Date());
  const cutDate = format(filterDate, 'yyyy-MM-dd');
  const maxDate = new Date();
  const openPortal = useOpenPortal();

  const isAllowedSetupBalance = useAllowedPermission({
    scope: 'company',
    action: 'set_initial_account_balance',
    resource: 'setupAccount',
  });

  const { summaryAccountsBalances, isLoading, isFetching } =
    useSummaryAccountsBalances({
      companyId,
      organizationId,
      cut_date: cutDate,
    });

  function handleRegisterInitialBalance(account: Account) {
    if (!isAllowedSetupBalance) return;
    onRegister?.(account);
  }

  function handleOpenBalanceInconsistency(
    account?: BalanceInconsistencyType[]
  ) {
    const balances = account ? account : summaryAccountsBalances;

    openPortal(BalanceInconsistency, {
      accounts: balances as BalanceInconsistencyType[],
    });
  }

  const columns: GridColDef[] = useMemo(() => {
    const defaultColumns = [
      accountColumn({
        editable: false,
        maxWidth: 140,
        resizable: false,
        groupable: false,
      }),
      dateColumn({ groupable: false }),
      amountColumn({
        editable: false,
        headerName: 'Saldos',
        resizable: false,
        minWidth: 175,
        aggregable: false,
        groupable: false,
        valueGetter: (value, row) => row?.accountBalance?.balance,
        renderCell: (props) => {
          const {
            pendingInitialBalance,
            accountBalance: { balance, balanceType },
            account,
            inconsistency,
          } = props.row;

          const isDebit = balanceType === BalanceType.DEBIT;
          const isInconsistency = !!inconsistency;

          const color =
            isInconsistency && !isDebit ? 'warning.600' : isDebit ? 'red' : '';

          if (pendingInitialBalance) {
            return (
              <Button
                color="primary"
                onClick={() => handleRegisterInitialBalance(account)}
                disabled={!isAllowedSetupBalance}
                className={styles.button}
              >
                Cadastrar saldo inicial
              </Button>
            );
          }

          return (
            <Box display="flex" gap={1} alignItems="center">
              <Typography color={color} fontWeight={600}>
                {toCurrency(balance, balanceType)}
              </Typography>

              {isInconsistency && (
                <Tooltip
                  title="Clique para verificar divergências encontradas entre o saldo exibido na FinBits e o saldo bancário desta conta."
                  enterDelay={100}
                  placement="top"
                >
                  <Chip
                    onClick={() =>
                      handleOpenBalanceInconsistency([
                        { account, inconsistency },
                      ])
                    }
                    className={styles.chip}
                    color="warning"
                    size="small"
                    icon={
                      <AlertTriangleIcon
                        data-testid="inconsistency-icon"
                        color="warning"
                        fontSize="xsmall"
                      />
                    }
                  />
                </Tooltip>
              )}
            </Box>
          );
        },
      }),
    ];

    return defaultColumns;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const existsBTG = summaryAccountsBalances.some(
    (item) => item.account.routingNumber === RoutingNumber.BTG
  );

  const existsPendencies = summaryAccountsBalances.some(
    (item) => item.pendingInitialBalance
  );

  const isAllBalancePending = summaryAccountsBalances.every(
    (item) => item.pendingInitialBalance === true
  );

  const countInconsistencies = summaryAccountsBalances.filter(
    (account) => account.inconsistency !== null
  ).length;

  const hasDifferentAccountBTGSaas = summaryAccountsBalances.some((account) => {
    const routingNumber = account.account.routingNumber;
    return (
      routingNumber !== RoutingNumber.BTG &&
      routingNumber !== RoutingNumber.ASAAS
    );
  });

  function handleFilterDate(date: Date | null) {
    setFilterDate(date || new Date());
  }

  return (
    <>
      {summaryAccountsBalances.length || isFetching ? (
        <>
          <Box p={4} pt={0}>
            {existsPendencies ? (
              <Alert severity="warning" variant="outlined">
                <AlertTitle>
                  Cadastre o saldo inicial da conta bancária adicionada
                </AlertTitle>
                Este passo é necessário para que os saldos sejam exibidos e
                atualizados corretamente na FinBits.
              </Alert>
            ) : (
              <>
                {hasDifferentAccountBTGSaas && (
                  <Alert
                    severity="info"
                    variant="outlined"
                    icon={<RefreshCW05Icon />}
                  >
                    Lançamentos de contas automáticas que não são do Banco BTG
                    podem levar <strong>até 24 horas</strong> para serem
                    importados e refletirem nos saldos.
                  </Alert>
                )}
              </>
            )}

            <Box className={styles.table}>
              <Table
                rows={summaryAccountsBalances}
                columns={columns}
                disabledMenuRightClick
                rowHeight={64}
                GridToolBarSlots={{
                  TableRootActions: () => (
                    <TotalSum
                      accountsBalances={summaryAccountsBalances}
                      description="Total"
                      titleToolTipPendency="Este valor pode apresentar divergência pois há uma ou mais pendências de inclusão de saldo inicial."
                      titleToolTipInconsistency="Clique para verificar divergências encontradas entre o saldo exibido na FinBits e o saldo bancário."
                      showToolTipPendency={existsPendencies}
                      totalInconsistencies={countInconsistencies}
                      onClick={() => handleOpenBalanceInconsistency()}
                    />
                  ),
                  QuickActions: () => (
                    <DatePicker
                      maxDate={maxDate}
                      disabled={isAllBalancePending}
                      slotProps={{
                        inputAdornment: {
                          position: 'start',
                        },
                        day: (dayProps) => {
                          const isDisabled = isAfter(dayProps.day, maxDate);
                          return {
                            className: isDisabled ? styles.disabledDay : '',
                          };
                        },
                      }}
                      className={styles.datePicker}
                      onChange={handleFilterDate}
                      value={filterDate}
                    />
                  ),
                }}
                getRowId={(row) => row.account.id}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                isLoading={isLoading || isFetching}
              />
            </Box>
          </Box>

          {!existsBTG && <BalanceCTABTG onClose={onClose} />}
        </>
      ) : (
        <InitialBalanceEmptyState onClose={onClose} />
      )}
    </>
  );
}
