import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { DialogContent, Slide, Stack } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { FormProvider, useForm } from 'react-hook-form';
import { CheckIcon } from 'src/mui/_icons';
import { FormField, Input } from 'src/mui/_scss';

import { Dialog, Typography } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCreatePublicApiToken } from 'src/libs/finbits/PublicApiTokens/PublicApiTokens';
import type {
  PublicApiToken,
  PublicApiTokenPostParams,
} from 'src/libs/finbits/PublicApiTokens/types';
import { PublicApiTokenResolver } from 'src/libs/finbits/PublicApiTokens/types';

import styles from './CreatePublicApiTokenModal.module.scss';
import PublicApiTokenAlert from './PublicApiTokenAlert';

export default function CreatePublicApiTokenModal({
  onClose,
  open = true,
  onExit,
}: PortalProps) {
  const form = useForm<PublicApiTokenPostParams>({
    defaultValues: {
      description: '',
    },
    resolver: zodResolver(PublicApiTokenResolver),
  });

  const { handleSubmit, watch } = form;

  const payload = useCompanyParams();

  const {
    createPublicApiToken,
    isLoading: isCreating,
    isError,
  } = useCreatePublicApiToken();

  const [newToken, setNewToken] = useState<PublicApiToken | undefined>(
    undefined
  );

  function onSubmit(data: PublicApiTokenPostParams) {
    createPublicApiToken(
      { ...data, ...payload },
      {
        onSuccess: (resultData) => {
          setNewToken(resultData);
        },
      }
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
    >
      <Dialog.Header title="Criar novo token" onClose={onClose} size="small" />
      <DialogContent dividers className={styles.content}>
        <Stack gap={4} mb={1}>
          <Typography variant="body1">
            Por favor, defina um nome ou descrição de identificação para gerar o
            token.
            <br />
            Em seguida, copie o código gerado para compartilhar com a sua equipe
            técnica.
          </Typography>
          <Stack
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            direction="row"
            alignItems="center"
            gap={5}
          >
            <div className={styles.formInput}>
              <FormProvider {...form}>
                <FormField
                  label="Nome ou descrição"
                  name="description"
                  helperText="Máx. 80 caracteres"
                  required
                >
                  {(field) => (
                    <Input
                      placeholder="Digite aqui..."
                      {...field}
                      disabled={isCreating || !!newToken}
                    />
                  )}
                </FormField>
              </FormProvider>
            </div>

            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={
                !watch('description').trim() || isCreating || !!newToken
              }
              loading={isCreating}
              startIcon={newToken && <CheckIcon />}
            >
              {newToken ? 'Token gerado' : 'Gerar token'}
            </LoadingButton>
          </Stack>
        </Stack>

        <PublicApiTokenAlert
          isError={isError}
          isCreating={isCreating}
          token={newToken}
        />
      </DialogContent>
    </Dialog>
  );
}
