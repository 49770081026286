import { useMemo } from 'react';

import type { GridApi } from '@mui/x-data-grid-premium';
import { Table } from 'src/mui/_scss';

import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import useEditCellValidation from 'src/features/entries/EntriesDataGrid/editCellEvents/useEditCellValidation';
import {
  TableRootActions,
  ToolbarQuickActions,
  ToolbarSelectionActions,
} from 'src/features/entries/EntriesDataGrid/GridToolBar';
import useEntriesParams from 'src/features/entries/useEntriesParams';

import ContextMenuRowActions from './ContextMenuRowActions/ContextMenuRowActions';
import useHandleProcessRowUpdate from './editCellEvents/useHandleProcessRowUpdate';
import { useBuildColumns } from './useBuildColumns';

type Props = {
  alertInfo?: string;
  classifications: Classification[];
  entries: FinancialStatementEntry[];
  loading: boolean;
  quickFilter: string[];
};

export const STORAGE_KEY = 'entries_data_grid_state_v2_';

export default function EntriesDataGrid({
  alertInfo,
  classifications,
  entries,
  loading,
  quickFilter,
}: Props) {
  const onCellEditStart = useEditCellValidation();
  const { isLoading: isSavingBatch, handleProcessRowUpdate } =
    useHandleProcessRowUpdate();

  const { isLoadingAccounts, companyId } = useEntriesParams();

  const isLoadingTable = useMemo(
    () => loading || isLoadingAccounts || isSavingBatch,
    [loading, isLoadingAccounts, isSavingBatch]
  );

  const columns = useBuildColumns(classifications);

  const memoizedTableRootActions = useMemo(
    () => (props: { apiRef: GridApi }) =>
      <TableRootActions {...props} alertInfo={alertInfo} />,
    [alertInfo]
  );

  return (
    <Table
      checkboxSelection
      storageKey={`${STORAGE_KEY}${companyId}`}
      quickFilter={quickFilter}
      isLoading={isLoadingTable}
      rows={entries}
      columns={columns}
      GridToolBarSlots={{
        SelectedActions: ToolbarSelectionActions,
        QuickActions: ToolbarQuickActions,
        TableRootActions: memoizedTableRootActions,
        selectedText: ['lançamento selecionado', 'lançamentos selecionados'],
      }}
      CustomContextMenuOptions={ContextMenuRowActions}
      onProcessRowUpdate={handleProcessRowUpdate}
      onCellEditStart={onCellEditStart}
    />
  );
}
