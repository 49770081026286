import { Box, Skeleton, Stack } from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { Typography } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import BotAvatar from 'src/features/finbot/BotAvatar';

import Feedback from '../Feedback';

import styles from './text.module.scss';

type Props = {
  id?: string | null;
  answer?: string | null;
  isFetching: boolean;
  isError?: boolean;
};

export default function BotText({ id, answer, isFetching, isError }: Props) {
  const { organizationId, companyId } = useCompanyParams();

  if (!answer && !isFetching && !isError) return null;

  return (
    <Box>
      <Stack
        alignSelf="start"
        flexDirection="row"
        gap={4}
        alignItems="start"
        className={styles.botWrapper}
      >
        <BotAvatar />

        {isError && (
          <Typography>
            Houve um erro ao tentar obter o insight. Tente novamente em
            instantes.
          </Typography>
        )}

        {isFetching ? (
          <div>
            <Skeleton width="40vw" />
            <Skeleton width="30vw" />
            <Skeleton width="40vw" />
          </div>
        ) : (
          <Stack direction="column">
            <ReactMarkdown>{answer}</ReactMarkdown>
          </Stack>
        )}
      </Stack>
      {!isFetching && !isError && (
        <Feedback
          insightId={id!}
          companyId={companyId}
          organizationId={organizationId}
        />
      )}
    </Box>
  );
}
